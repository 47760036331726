import { defineStore } from 'pinia'
import { ref } from 'vue'
import type UserInfo from '@/models/UserInfo'
import type Team from '@/models/Team'
import Config from '@/utils/config'

export const useUserStore = defineStore(
  'user',
  () => {
    // state
    const deviceId = ref<string | undefined>(undefined)
    const language = ref<string>(
      typeof navigator != 'undefined' &&
        Config.languages.find(
          (language) => language === navigator.language.slice(0, 2)
        )
        ? window.location.host.endsWith('.de')
          ? 'de'
          : 'en'
        : 'en'
    )
    const userInfo = ref<UserInfo | undefined>(undefined)
    const token = ref<string | undefined>(undefined)
    const teamId = ref<string | undefined>(undefined)

    // getters
    const getDeviceId = () => {
      if (!deviceId.value) {
        deviceId.value = [...Array(24)]
          .map(() => Math.floor(Math.random() * 16).toString(16))
          .join('')
      }
      return deviceId.value
    }
    const isLoggedIn = () => !!token.value
    const team = (): Team | undefined => {
      if (!userInfo.value || !teamId.value) {
        return undefined
      }
      return userInfo.value?.teams?.find((t) => t.id === teamId.value)
    }

    return {
      language,
      teamId,
      team,
      token,
      userInfo,
      getDeviceId,
      isLoggedIn,
    }
  },
  { persist: !import.meta.env.SSR }
)
