<template>
  <div class="p-4 md:p-16">
    <n-card v-if="!isInfoRoute" class="p-4" :embedded="true">
      <div class="flex flex-col xl:flex-row">
        <div class="grow"></div>
        <div class="xl:w-1/2">
          <h2 class="mt-8 mb-4 text-center text-green-300">
            {{ t('home.introTitle') }}
          </h2>
          <p class="text-lg leading-relaxed">
            {{ t('home.introText1') }}
          </p>
          <p class="text-lg leading-relaxed">
            {{ t('home.introText2') }}
          </p>

          <p class="mt-8 text-center">
            <RouterLink
              :to="{ name: 'start' }"
            >
              <n-button type="primary" size="large">
                {{ t('home.try') }}
              </n-button>
            </RouterLink>
          </p>

          <h2 class="mt-10 text-center text-green-300">
            {{ t('home.toolsTitle') }}
          </h2>
          <div class="flex flex-col lg:flex-row gap-5 justify-center">
            <RouterLink :to="{ name: 'info.text' }">
              <div class="p-8 flex flex-col items-center gap-5">
                <Chat class="w-[120px]" />
                <ul class="text-black dark:text-white">
                  <li>{{ t('home.group.textItem1') }}</li>
                  <li>{{ t('home.group.textItem2') }}</li>
                  <li>{{ t('home.group.textItem3') }}</li>
                </ul>
              </div>
            </RouterLink>
            <RouterLink :to="{ name: 'info.image' }">
              <div class="p-8 flex flex-col items-center gap-5">
                <Image class="w-[120px]" />
                <ul class="text-black dark:text-white">
                  <li>{{ t('home.group.imageItem1') }}</li>
                  <li>{{ t('home.group.imageItem2') }}</li>
                  <li>{{ t('home.group.imageItem3') }}</li>
                </ul>
              </div>
            </RouterLink>
            <RouterLink :to="{ name: 'info.audio' }">
              <div class="p-8 flex flex-col items-center gap-5">
                <Voice class="w-[120px]" />
                <ul class="text-black dark:text-white">
                  <li>{{ t('home.group.voiceItem1') }}</li>
                  <li>{{ t('home.group.voiceItem2') }}</li>
                  <li>{{ t('home.group.voiceItem3') }}</li>
                </ul>
              </div>
            </RouterLink>
          </div>
        </div>
        <div class="grow"></div>
        <div class="xl:w-1/3">
          <img
            class="w-full max-w-[500px] mx-auto"
            src="@/assets/images/cover.webp"
          />
        </div>
        <div class="grow"></div>
      </div>
    </n-card>

    <n-card v-if="!isInfoRoute" class="mt-4 p-4" :embedded="true">
      <div class="flex flex-col md:flex-row md:gap-8">
        <div class="grow"></div>
        <div class="md:w-2/5">
          <ul>
            <MultilangHtml is="li" class="p-2" :html="t('home.argument1')" />
            <MultilangHtml is="li" class="p-2" :html="t('home.argument2')" />
            <MultilangHtml is="li" class="p-2" :html="t('home.argument3')" />
          </ul>
          <p class="mt-8 text-center">
            <RouterLink
              :to="{ name: 'start' }"
            >
              <n-button type="primary" size="large">
                {{ t('home.try') }}
              </n-button>
            </RouterLink>
          </p>
        </div>
        <div class="grow"></div>
        <div class="md:w-2/5">
          <p class="mb-4">{{ t('home.text') }}</p>
          <p class="mt-8 text-center">
            <RouterLink :to="{ name: 'info.text' }">
              <n-button size="large" type="primary">{{ t('home.cta') }}</n-button>
            </RouterLink>
          </p>
        </div>
        <div class="grow"></div>
      </div>
    </n-card>

    <n-card v-if="isInfoRoute" class="mt-4 p-4" :embedded="true">
      <div class="flex flex-col md:flex-row gap-5 justify-center">
        <RouterLink :to="{ name: 'info.text' }">
          <div class="px-10 flex flex-row items-center gap-5">
            <Chat class="w-[24px]" />
            <div class="text-black dark:text-white">
              {{ t('home.group.text') }}
            </div>
          </div>
        </RouterLink>
        <RouterLink :to="{ name: 'info.image' }">
          <div class="px-10 flex flex-row items-center gap-5">
            <Image class="w-[24px]" />
            <div class="text-black dark:text-white">
              {{ t('home.group.image') }}
            </div>
          </div>
        </RouterLink>
        <RouterLink :to="{ name: 'info.audio' }">
          <div class="px-10 flex flex-row items-center gap-5">
            <Voice class="w-[24px]" />
            <div class="text-black dark:text-white">
              {{ t('home.group.voice') }}
            </div>
          </div>
        </RouterLink>
      </div>
    </n-card>

    <n-card
      v-if="route.name === 'info.image'"
      :embedded="true"
      class="mt-4 p-4"
    >
      <div class="flex flex-col md:flex-row gap-4">
        <div class="flex-grow flex-auto">
          <h1>{{ t('home.info.imageGenerator.title') }}</h1>
          <p>
            {{ t('home.info.imageGenerator.text1') }}
          </p>
          <p>
            {{ t('home.info.imageGenerator.text2') }}
          </p>
          <p>
            <i>{{ t('home.info.imageGenerator.tip') }}</i>
          </p>
          <p class="mt-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.imageGenerator.sellingPoint1') }}
          </p>
          <p class="mt-4">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.imageGenerator.sellingPoint2') }}
          </p>
          <p class="mt-4 mb-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.imageGenerator.sellingPoint3') }}
          </p>
          <RouterLink
            :to="{ name: userStore.isLoggedIn() ? 'imageGenerator' : 'start' }"
          >
            <n-button type="primary">{{
              userStore.isLoggedIn() ? t('home.use') : t('home.try')
            }}</n-button>
          </RouterLink>
          <RouterLink :to="{ name: 'info.image.generator' }" class="ml-8">
            {{ t('home.moreInfo') }}
          </RouterLink>
        </div>
        <div class="flex-grow-0 flex flex-col md:max-w-[25%] gap-3">
          <img
            class="rounded"
            src="@/assets/images/image-generator/owl-suit.png"
          />
          <span class="place-self-center italic">{{
            t('home.info.imageGenerator.image1')
          }}</span>
        </div>
        <div class="flex-grow-0 flex flex-col md:max-w-[25%] gap-3">
          <img
            class="rounded"
            src="@/assets/images/image-generator/painting-bear.png"
          />
          <span class="place-self-center italic">{{
            t('home.info.imageGenerator.image2')
          }}</span>
        </div>
      </div>
    </n-card>
    <n-card
      v-if="route.name === 'info.image'"
      :embedded="true"
      class="mt-4 p-4"
    >
      <div class="flex flex-col md:flex-row gap-4">
        <div class="md:w-1/2 flex-grow flex-auto">
          <h1>{{ t('home.info.imageUpscaler.title') }}</h1>
          <p>
            {{ t('home.info.imageUpscaler.text1') }}
          </p>
          <p>
            {{ t('home.info.imageUpscaler.text2') }}
          </p>
          <p>
            <i>{{ t('home.info.imageUpscaler.tip') }}</i>
          </p>
          <p class="mt-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.imageUpscaler.sellingPoint1') }}
          </p>
          <p class="mt-4">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.imageUpscaler.sellingPoint2') }}
          </p>
          <p class="mt-4 mb-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.imageUpscaler.sellingPoint3') }}
          </p>
          <RouterLink
            :to="{ name: userStore.isLoggedIn() ? 'upscaler' : 'start' }"
          >
            <n-button type="primary">{{
              userStore.isLoggedIn() ? t('home.use') : t('home.try')
            }}</n-button>
          </RouterLink>
          <RouterLink :to="{ name: 'info.image.upscaler' }" class="ml-8">
            {{ t('home.moreInfo') }}
          </RouterLink>
        </div>
        <div class="md:w-1/2">
          <div class="w-full flex flex-col items-center">
            <img
              class="w-full max-w-[500px]"
              src="@/assets/images/upscaling.webp"
            />
          </div>
        </div>
      </div>
    </n-card>

    <n-card
      v-if="route.name === 'info.image'"
      :embedded="true"
      class="mt-4 p-4"
    >
      <div class="flex flex-col md:flex-row gap-4">
        <div class="md:w-1/2 flex-grow flex-auto">
          <h1>{{ t('home.info.imageBackground.title') }}</h1>
          <p>
            {{ t('home.info.imageBackground.text1') }}
          </p>
          <p>
            {{ t('home.info.imageBackground.text2') }}
          </p>
          <p>
            <i>{{ t('home.info.imageBackground.tip') }}</i>
          </p>
          <p class="mt-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.imageBackground.sellingPoint1') }}
          </p>
          <p class="mt-4">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.imageBackground.sellingPoint2') }}
          </p>
          <p class="mt-4 mb-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.imageBackground.sellingPoint3') }}
          </p>
          <RouterLink
            :to="{
              name: userStore.isLoggedIn() ? 'image-background' : 'start',
            }"
          >
            <n-button type="primary">{{
              userStore.isLoggedIn() ? t('home.use') : t('home.try')
            }}</n-button>
          </RouterLink>
          <RouterLink
            :to="{ name: 'info.image.image-background' }"
            class="ml-8"
          >
            {{ t('home.moreInfo') }}
          </RouterLink>
        </div>
        <div class="md:w-1/2">
          <div class="w-full flex flex-col items-center">
            <img
              class="w-full max-w-[500px]"
              src="@/assets/images/image-background.webp"
            />
          </div>
        </div>
      </div>
    </n-card>

    <n-card
      v-if="route.name === 'info.image'"
      :embedded="true"
      class="mt-4 p-4"
    >
      <div class="flex flex-col md:flex-row gap-4">
        <div class="md:w-1/2 flex-grow flex-auto">
          <h1>{{ t('home.info.imageToImage.title') }}</h1>
          <p>
            {{ t('home.info.imageToImage.text1') }}
          </p>
          <p>
            {{ t('home.info.imageToImage.text2') }}
          </p>
          <p>
            <i>{{ t('home.info.imageToImage.tip') }}</i>
          </p>
          <p class="mt-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.imageToImage.sellingPoint1') }}
          </p>
          <p class="mt-4">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.imageToImage.sellingPoint2') }}
          </p>
          <p class="mt-4 mb-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.imageToImage.sellingPoint3') }}
          </p>
          <RouterLink
            :to="{ name: userStore.isLoggedIn() ? 'image-to-image' : 'start' }"
          >
            <n-button type="primary">{{
              userStore.isLoggedIn() ? t('home.use') : t('home.try')
            }}</n-button>
          </RouterLink>
          <RouterLink :to="{ name: 'info.image.image-to-image' }" class="ml-8">
            {{ t('home.moreInfo') }}
          </RouterLink>
        </div>
        <div class="md:w-1/2">
          <div class="w-full flex flex-col items-center"></div>
        </div>
      </div>
    </n-card>

    <n-card v-if="route.name === 'info.text'" :embedded="true" class="mt-4 p-4">
      <div class="flex flex-col md:flex-row gap-4">
        <div class="flex-grow-0 flex flex-col md:max-w-[25%] gap-3">
          <img
            class="rounded"
            src="@/assets/images/chat/around-the-clock.png"
          />
          <img class="mt-2 rounded" src="@/assets/images/chat/chat.png" />
        </div>
        <div class="flex-grow flex-auto">
          <h1>{{ t('home.info.chat.title') }}</h1>
          <p>
            {{ t('home.info.chat.text1') }}
          </p>
          <p>
            {{ t('home.info.chat.text2') }}
          </p>
          <p>
            <i>{{ t('home.info.chat.tip') }}</i>
          </p>
          <p class="mt-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.chat.sellingPoint1') }}
          </p>
          <p class="mt-4">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.chat.sellingPoint2') }}
          </p>
          <p class="mt-4 mb-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.chat.sellingPoint3') }}
          </p>
          <RouterLink :to="{ name: userStore.isLoggedIn() ? 'chat' : 'start' }">
            <n-button type="primary">{{
              userStore.isLoggedIn() ? t('home.use') : t('home.try')
            }}</n-button>
          </RouterLink>
          <RouterLink :to="{ name: 'info.text.chat' }" class="ml-8">
            {{ t('home.moreInfo') }}
          </RouterLink>
        </div>
      </div>
    </n-card>
    <n-card v-if="route.name === 'info.text'" :embedded="true" class="mt-4 p-4">
      <div class="flex flex-col md:flex-row gap-4">
        <div class="flex-grow flex-auto">
          <h1>{{ t('home.info.textTool.title') }}</h1>
          <p>
            {{ t('home.info.textTool.text1') }}
          </p>
          <p>
            {{ t('home.info.textTool.text2') }}
          </p>
          <p>
            <i>{{ t('home.info.textTool.tip') }}</i>
          </p>
          <p class="mt-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.textTool.sellingPoint1') }}
          </p>
          <p class="mt-4">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.textTool.sellingPoint2') }}
          </p>
          <p class="mt-4 mb-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.textTool.sellingPoint3') }}
          </p>
          <RouterLink
            :to="{ name: userStore.isLoggedIn() ? 'textTool' : 'start' }"
          >
            <n-button type="primary">{{
              userStore.isLoggedIn() ? t('home.use') : t('home.try')
            }}</n-button>
          </RouterLink>
          <RouterLink :to="{ name: 'info.text.tool' }" class="ml-8">
            {{ t('home.moreInfo') }}
          </RouterLink>
        </div>
        <div class="flex-grow-0 flex flex-col md:max-w-[25%] gap-3">
          <img class="rounded" src="@/assets/images/text-tool/noble-book.png" />
          <img
            class="mt-2 rounded"
            src="@/assets/images/text-tool/stone-phone.png"
          />
        </div>
      </div>
    </n-card>
    <n-card v-if="route.name === 'info.text'" :embedded="true" class="mt-4 p-4">
      <div class="flex flex-col md:flex-row gap-4">
        <div class="flex-grow-0 flex flex-col md:max-w-[25%] gap-3">
          <img class="rounded" src="@/assets/images/summarizer/tldr.png" />
        </div>
        <div class="flex-grow flex-auto">
          <h1>{{ t('home.info.summarizer.title') }}</h1>
          <p>
            {{ t('home.info.summarizer.text1') }}
          </p>
          <p>
            {{ t('home.info.summarizer.text2') }}
          </p>
          <p>
            <i>{{ t('home.info.summarizer.tip') }}</i>
          </p>
          <p class="mt-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.summarizer.sellingPoint1') }}
          </p>
          <p class="mt-4">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.summarizer.sellingPoint2') }}
          </p>
          <p class="mt-4 mb-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.summarizer.sellingPoint3') }}
          </p>
          <RouterLink
            :to="{ name: userStore.isLoggedIn() ? 'summarizer' : 'start' }"
          >
            <n-button type="primary">{{
              userStore.isLoggedIn() ? t('home.use') : t('home.try')
            }}</n-button>
          </RouterLink>
          <RouterLink :to="{ name: 'info.text.summarizer' }" class="ml-8">
            {{ t('home.moreInfo') }}
          </RouterLink>
        </div>
      </div>
    </n-card>
    <n-card v-if="route.name === 'info.text'" :embedded="true" class="mt-4 p-4">
      <div class="flex flex-col md:flex-row gap-4">
        <div class="flex-grow flex-auto">
          <h1>{{ t('home.info.translator.title') }}</h1>
          <p>
            {{ t('home.info.translator.text1') }}
          </p>
          <p>
            {{ t('home.info.translator.text2') }}
          </p>
          <p>
            <i>{{ t('home.info.translator.tip') }}</i>
          </p>
          <p class="mt-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.translator.sellingPoint1') }}
          </p>
          <p class="mt-4">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.translator.sellingPoint2') }}
          </p>
          <p class="mt-4 mb-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.translator.sellingPoint3') }}
          </p>
          <RouterLink
            :to="{ name: userStore.isLoggedIn() ? 'translator' : 'start' }"
          >
            <n-button type="primary">{{
              userStore.isLoggedIn() ? t('home.use') : t('home.try')
            }}</n-button>
          </RouterLink>
          <RouterLink :to="{ name: 'info.text.translator' }" class="ml-8">
            {{ t('home.moreInfo') }}
          </RouterLink>
        </div>
      </div>
    </n-card>
    <n-card
      v-if="route.name === 'info.audio'"
      :embedded="true"
      class="mt-4 p-4"
    >
      <div class="flex flex-col md:flex-row gap-4">
        <div class="md:w-2/3 flex-grow flex-auto">
          <h1>{{ t('home.info.voiceGenerator.title') }}</h1>
          <p>
            {{ t('home.info.voiceGenerator.text1') }}
          </p>
          <p>
            <i>{{ t('home.info.voiceGenerator.tip') }}</i>
          </p>
          <p class="mt-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.voiceGenerator.sellingPoint1') }}
          </p>
          <p class="mt-4">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.voiceGenerator.sellingPoint2') }}
          </p>
          <p class="mt-4 mb-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.voiceGenerator.sellingPoint3') }}
          </p>
          <RouterLink
            :to="{ name: userStore.isLoggedIn() ? 'voiceGenerator' : 'start' }"
          >
            <n-button type="primary">{{
              userStore.isLoggedIn() ? t('home.use') : t('home.try')
            }}</n-button>
          </RouterLink>
          <RouterLink :to="{ name: 'info.audio.voiceGenerator' }" class="ml-8">
            {{ t('home.moreInfo') }}
          </RouterLink>
        </div>
        <div class="md:w-1/3 flex flex-col items-center">
          <img class="w-full max-w-[500px]" src="@/assets/images/voice.webp" />
        </div>
      </div>
    </n-card>
    <n-card v-if="route.name === 'info.text'" :embedded="true" class="mt-4 p-4">
      <div class="flex flex-col md:flex-row gap-4">
        <div class="flex-grow flex-auto">
          <h1>{{ t('home.info.talkTo.title') }}</h1>
          <p>
            {{ t('home.info.TalkTo.text1') }}
          </p>
          <p>
            {{ t('home.info.talkTo.text2') }}
          </p>
          <p>
            {{ t('home.info.talkTo.text3') }}
          </p>
          <p>
            <i>{{ t('home.info.talkTo.tip') }}</i>
          </p>
          <p class="mt-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.talkTo.sellingPoint1') }}
          </p>
          <p class="mt-4">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.talkTo.sellingPoint2') }}
          </p>
          <p class="mt-4">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.talkTo.sellingPoint3') }}
          </p>
          <p class="mt-4 mb-8">
            <n-icon class="mb-[-4px]" color="green" :size="18">
              <CheckOutlined />
            </n-icon>
            {{ t('home.info.talkTo.sellingPoint4') }}
          </p>
          <RouterLink
            :to="{ name: userStore.isLoggedIn() ? 'talkTo' : 'start' }"
          >
            <n-button type="primary">{{
              userStore.isLoggedIn() ? t('home.use') : t('home.try')
            }}</n-button>
          </RouterLink>
          <RouterLink :to="{ name: 'info.text.talkTo' }" class="ml-8">
            {{ t('home.moreInfo') }}
          </RouterLink>
        </div>
      </div>
    </n-card>
  </div>
</template>

<script setup lang="ts">
import { NButton, NCard, NIcon } from 'naive-ui'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/UserStore'
import Chat from '@/assets/icons/Chat.svg'
import CheckOutlined from '@/assets/icons/CheckOutlined.svg'
import Image from '@/assets/icons/Image.svg'
import Voice from '@/assets/icons/Voice.svg'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import MultilangHtml from '@/components/MultilangHtml.vue';

const { t } = useI18n()
const route = useRoute()
const userStore = useUserStore()

const isInfoRoute = computed(() => route.name?.toString().startsWith('info'))
</script>
