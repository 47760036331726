import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'
import type { Vue } from '@sentry/vue/types/types'

export const initializeSentry = (app: Vue, router: Router) => {
  if (!import.meta.env.VITE_SENTRY_DSN) return

  const sentryIntegrations = import.meta.env.SSR
    ? []
    : [
        new Sentry.BrowserTracing({
          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            'localhost',
            /^https?:\/\/.*api\.ki-toolbox\.de/,
          ],
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ]

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENV,
    integrations: sentryIntegrations,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
