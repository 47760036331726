<template>
  <div class="p-4 md:p-16">
    <n-card :embedded="true">
      <h1 class="text-center">{{ t('startConfirm.title') }}</h1>
      <p v-html="t('startConfirm.text')"></p>
      <n-button
        v-if="redirect"
        type="primary"
        class="mt-4"
        @click="router.push(redirect)"
      >
        {{ t('startConfirm.continue') }}
      </n-button>
    </n-card>
  </div>
</template>

<script setup lang="ts">
import { NButton, NCard } from 'naive-ui'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const redirect = computed(() => route.query.redirect as string)
</script>
