<template>
  <nav class="bg-black bg-opacity-30 text-l flex w-full">
    <div
      v-for="itemGroup in itemGroups"
      :key="itemGroup.key"
      class="flex flex-col m-8"
    >
      <div v-for="item in itemGroup.items" :key="item.label" class="m-1">
        <router-link v-if="!item.to.includes('://')" class="text-white font-semibold" :to="item.to">{{
          item.label
        }}</router-link>
        <a v-if="item.to.includes('://')" class="text-white font-semibold" :href="item.to">{{
          item.label
        }}</a>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const { t } = useI18n()

const itemGroups = computed(() => [
  {
    key: 'terms',
    items: [
      {
        label: t('footer.blog'),
        to: '/content/blog',
      },
      {
        label: t('footer.imprint'),
        to: '/content/imprint',
      },
      {
        label: t('footer.partner'),
        to: '/content/partner',
      },
    ],
  },
  {
    key: 'social',
    items: [
      {
        label: t('footer.facebook'),
        to: 'https://www.facebook.com/profile.php?id=100090845614447',
      },
      {
        label: t('footer.linkedin'),
        to: 'https://www.linkedin.com/company/90583113',
      },
    ],
  },
  {
    key: 'language',
    items: [
      {
        label: t('languages.en'),
        to: '/en',
      },
      {
        label: t('languages.de'),
        to: '/de',
      },
    ],
  },
])
</script>
