<template>
  <n-float-button
    v-if="breakpoints.smaller('lg').value"
    @click="show = !show"
    class="z-20"
    :right="16"
    :bottom="16"
    shape="circle"
  >
    <n-icon>
      <Library />
    </n-icon>
  </n-float-button>
  <div
    v-if="breakpoints.greaterOrEqual('lg').value || show"
    class="z-10 h-full overflow-y-auto"
    :class="{
      'fixed top-0 bottom-0 left-0 right-0': breakpoints.smaller('lg').value,
    }"
  >
    <n-card
      :embedded="true"
      contentStyle="height: 100%; display: flex; flex-direction: column; gap: 16px"
      style="min-height: 100%; --n-border-radius: 0px"
    >
      <slot name="header">
        <div class="flex-grow-0">
          <h1 class="text-center">{{ t('library.title') }}</h1>
          <p>{{ t('library.text') }}</p>
        </div>
      </slot>
      <slot name="default">
        <n-spin size="large" :show="loading">
          <n-collapse :default-expanded-names="['history']">
            <LibrarySection
              owner="user"
              type="history"
              :title="t('library.history')"
              :data="getData('user', 'history')"
              can-edit-conversations
              @clearHistory="clearHistoryConfirm"
              @deleteConversation="deleteConversationConfirm"
              @openConversation="openConversation"
            />
            <LibrarySection
              owner="user"
              type="template"
              :title="t('library.myLibrary')"
              :data="getData('user', 'template')"
              can-edit-conversations
              can-edit-folders
              @createFolder="createFolderModal"
              @deleteConversation="deleteConversationConfirm"
              @deleteFolder="deleteFolderConfirm"
              @openConversation="openConversation"
            />
            <LibrarySection
              owner="team"
              type="template"
              :title="t('library.teamLibrary')"
              :data="getData('team', 'template')"
              can-edit-conversations
              can-edit-folders
              @createFolder="createFolderModal"
              @deleteConversation="deleteConversationConfirm"
              @deleteFolder="deleteFolderConfirm"
              @openConversation="openConversation"
            />
            <LibrarySection
              owner="global"
              type="samples"
              :title="t('library.samples')"
              :data="getData('global', 'samples')"
              @openConversation="openConversation"
            />
          </n-collapse>
        </n-spin>
      </slot>
    </n-card>

    <n-modal
      :show="showCreateFolderModal"
      preset="dialog"
      :show-icon="false"
      :title="t('library.createFolder')"
      @close="showCreateFolderModal = false"
    >
      <template #action>
        <n-button @click="showCreateFolderModal = false">
          {{ t('common.cancel') }}
        </n-button>
        <n-button
          :loading="loading"
          :disabled="!createFolderName || loading"
          type="primary"
          @click="createFolder"
        >
          {{ t('common.create') }}
        </n-button>
      </template>
      <n-input
        class="my-4"
        v-model:value="createFolderName"
        :placeholder="t('library.folderName')"
      />
    </n-modal>
  </div>
</template>

<script lang="ts" setup>
import {
  NButton,
  NCard,
  NCollapse,
  NFloatButton,
  NIcon,
  NInput,
  NModal,
  NSpin,
  type TreeOption,
} from 'naive-ui'
import { useI18n } from 'vue-i18n'
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { useDialog, useMessage } from 'naive-ui'
import FolderService from '@/services/FolderService'
import { useUserStore } from '@/stores/UserStore'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import type Folder from '@/models/Folder'
import Library from '@/assets/icons/Library.svg'
import LibrarySection from '@/components/library/LibrarySection.vue'
import ConversationService from '@/services/ConversationService'
import type Conversation from '@/models/Conversation'
import { emit } from '@/main'
import { useRouter } from 'vue-router'

onMounted(() => {
  addHandler('ConversationCreated', (conversation: any) => {
    conversations.value.push(conversation)
  })
  addHandler('ConversationUpdated', (conversation: any) => {
    const index = conversations.value.findIndex((c) => c.id == conversation.id)
    if (index != -1) {
      conversations.value[index] = conversation
    }
  })
  addHandler('ConversationDeleted', (conversationId: any) => {
    conversations.value.splice(
      conversations.value.findIndex(
        (conversation) => conversation.id == conversationId
      ),
      1
    )
  })
  addHandler('FolderCreated', (folder: any) => {
    folders.value.push(folder)
  })
  addHandler('FolderUpdated', (folder: any) => {
    const index = folders.value.findIndex((f) => f.id == folder.id)
    if (index != -1) {
      folders.value[index] = folder
    }
  })
  addHandler('FolderDeleted', (folderId: any) => {
    folders.value.splice(
      folders.value.findIndex((folder) => folder.id == folderId),
      1
    )
  })
})

onUnmounted(() => {
  handlers.forEach(({ type, handler }) => {
    emit.off(type, handler)
  })
})

const addHandler = (type: string, handler: any) => {
  emit.on(type, handler)
  handlers.push({ type, handler })
}

const { t } = useI18n()
const breakpoints = useBreakpoints(breakpointsTailwind)
const conversations = ref<Conversation[]>([])
const createFolderName = ref('')
const createFolderOwner = ref('')
const createFolderParentId = ref<string | null>(null)
const createFolderType = ref('')
const dialog = useDialog()
const folders = ref<Folder[]>([])
const handlers: { type: string; handler: any }[] = []
const loading = ref(true)
const message = useMessage()
const router = useRouter()
const show = ref(false)
const showCreateFolderModal = ref(false)
const userStore = useUserStore()

const clearHistoryConfirm = () => {
  dialog.warning({
    title: t('library.clearHistory'),
    content: t('library.clearHistoryConfirm'),
    positiveText: t('common.yes'),
    negativeText: t('common.no'),
    onPositiveClick: () => clearHistory(),
  })
}

const clearHistory = () => {
  loading.value = true
  ConversationService.clearHistory()
    .catch(() => {
      message.error(t('messages.generalError'), {
        closable: true,
        duration: 3000,
      })
    })
    .finally(() => {
      loading.value = false
    })
}

const createFolder = () => {
  loading.value = true
  FolderService.createFolder(
    createFolderOwner.value,
    createFolderType.value,
    createFolderParentId.value,
    createFolderName.value
  )
    .catch(() => {
      message.error(t('messages.generalError'), {
        closable: true,
        duration: 3000,
      })
    })
    .finally(() => {
      showCreateFolderModal.value = false
      loading.value = false
    })
}

const createFolderModal = (
  owner: string,
  type: string,
  parentId: string | null
) => {
  createFolderOwner.value = owner
  createFolderType.value = type
  createFolderName.value = ''
  createFolderParentId.value = parentId
  showCreateFolderModal.value = true
}

const deleteConversationConfirm = (id: string, name: string) => {
  dialog.warning({
    title: t('library.deleteConversation'),
    content: t('library.deleteConversationConfirm', { name }),
    positiveText: t('common.yes'),
    negativeText: t('common.no'),
    onPositiveClick: () => deleteConversation(id),
  })
}

const deleteConversation = (id: string) => {
  loading.value = true
  ConversationService.deleteConversation(id)
    .catch(() => {
      message.error(t('messages.generalError'), {
        closable: true,
        duration: 3000,
      })
    })
    .finally(() => {
      loading.value = false
    })
}

const deleteFolderConfirm = (id: string, name: string) => {
  dialog.warning({
    title: t('library.deleteFolder'),
    content: t('library.deleteFolderConfirm', { name }),
    positiveText: t('common.yes'),
    negativeText: t('common.no'),
    onPositiveClick: () => deleteFolder(id),
  })
}

const deleteFolder = (id: string) => {
  loading.value = true
  FolderService.deleteFolder(id)
    .catch(() => {
      message.error(t('messages.generalError'), {
        closable: true,
        duration: 3000,
      })
    })
    .finally(() => {
      loading.value = false
    })
}

const getData = (
  owner: string,
  type: string,
  parentId?: string
): TreeOption[] => {
  const dataFolders = folders.value
    .filter(
      (folder) =>
        folder.parent_id == parentId &&
        owner == folder.owner &&
        type == folder.type
    )
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((folder) => {
      return {
        type: 'folder',
        label: folder.name,
        key: folder.id,
        children: getData(owner, type, folder.id),
      }
    })

  const dataConversations = conversations.value
    .filter(
      (conversation) =>
        owner == conversation.owner &&
        type == conversation.type &&
        ((!parentId && !conversation.folder_id) ||
          parentId == conversation.folder_id)
    )
    .sort((a, b) => {
      if (type == 'history') {
        return b.created_at?.getTime()! - a.created_at?.getTime()!
      } else {
        return a.name.localeCompare(b.name)
      }
    })
    .map((conversation) => {
      return {
        type: 'conversation',
        is_template: conversation.is_template,
        label: conversation.name,
        key: conversation.id,
      }
    })

  return [...dataFolders, ...dataConversations]
}

const getFolders = () => {
  loading.value = true
  FolderService.getFolders()
    .then((res) => {
      folders.value = res ?? []
    })
    .catch(() => {
      message.error(t('messages.generalError'), {
        closable: true,
        duration: 3000,
      })
    })
    .finally(() => {
      loading.value = false
    })
}

const getConversations = () => {
  loading.value = true
  ConversationService.getConversations()
    .then((res) => {
      conversations.value = res ?? []
    })
    .catch(() => {
      message.error(t('messages.generalError'), {
        closable: true,
        duration: 3000,
      })
    })
    .finally(() => {
      loading.value = false
    })
}

const openConversation = (conversationId: string) => {
  router.push({ name: 'chat', params: { conversationId } })
  show.value = false
}

watch(
  () => userStore.teamId,
  () => {
    getFolders()
    getConversations()
  },
  { immediate: true }
)
</script>
