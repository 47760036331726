import type Conversation from '@/models/Conversation'
import ApiService from '@/services/ApiService'
import type ApiResponse from '@/responses/ApiResponse'
import UserService from '@/services/UserService'
import type Message from '@/models/Message'

export default class ConversationService {
  public static clearHistory(): Promise<void> {
    return ApiService.delete(`conversation/history`)
  }

  public static createConversation(
    model: string,
    messages: Message[],
    temperature: number,
    owner: string,
    type: string,
    folder_id?: string,
    name?: string,
    is_template?: boolean
  ): Promise<Conversation | undefined> {
    return ApiService.post<ApiResponse<Conversation>>('conversation', {
      folder_id,
      is_template,
      messages,
      model,
      name,
      owner,
      temperature,
      type,
    }).then((response) => response.data)
  }

  public static async continueConversation(
    conversationId: string,
    messages: {
      role: string
      content:
        | string
        | { type: string; text?: string; image_url?: { url: string } }[]
    }[],
    messageCallback: (contentDelta: string) => void,
    doneCallback: (messageId: string) => void,
    errorCallback: (error?: string) => void
  ): Promise<void> {
    let messageId = ''
    await ApiService.sseStream(
      'POST',
      `conversation/${conversationId}/continue`,
      {},
      { messages },
      (message) => {
        const chunk = JSON.parse(message)
        if (chunk.delta) {
          messageCallback(chunk.delta)
        }
        if (chunk.message_id) {
          messageId = chunk.message_id
        }
      },
      () => {
        doneCallback(messageId)
        UserService.updateUserInfo()
      },
      errorCallback
    ).catch((error) => {
      errorCallback(error?.response?.data?.message)
    })
  }

  public static deleteConversation(conversationId: string): Promise<void> {
    return ApiService.delete(`conversation/${conversationId}`)
  }

  public static deleteMessage(
    conversationId: string,
    messageId: string
  ): Promise<void> {
    return ApiService.delete(
      `conversation/${conversationId}/messages/${messageId}`
    )
  }

  public static getConversation(
    conversationId: string
  ): Promise<Conversation | undefined> {
    return ApiService.get<ApiResponse<Conversation>>(
      `conversation/${conversationId}`
    ).then((response) => response.data)
  }

  public static getConversations(): Promise<Conversation[] | undefined> {
    return ApiService.get<ApiResponse<Conversation[]>>('conversation').then(
      (response) => response.data
    )
  }

  public static updateConversation(
    conversation: Conversation
  ): Promise<Conversation | undefined> {
    return ApiService.put<ApiResponse<Conversation>>(
      `conversation/${conversation.id}`,
      conversation
    ).then((response) => response.data)
  }
}
