<template>
  <nav class="bg-black bg-opacity-30 text-l md:text-2xl">
    <ul class="w-full flex items-center pr-2 list-none">
      <li class="m-6 md:m-8 block md:hidden">
        <n-icon
          color="white"
          class="cursor-pointer text-[36px] md:text-[48px]"
          @click="emit('menu-click')"
        >
          <Menu />
        </n-icon>
      </li>
      <li class="m-2 md:m-8 flex-shrink flex-grow md:flex-grow-0">
        <router-link class="text-white font-semibold" to="/">
          <span v-if="language === 'de'">
            <LogoDe class="w-full h-auto max-h-6 md:max-h-8" />
          </span>
          <span v-if="language !== 'de'">
            <LogoEn class="w-full h-auto max-h-6 md:max-h-8" />
          </span>
        </router-link>
      </li>
      <li class="flex-1">
        <div class="flex">
          <div
            v-for="item in userStore.isLoggedIn() ? itemsLoggedIn : items"
            :key="item.label"
            class="md:block flex-1 m-2 md:m-8 text-center"
            :class="{ hidden: !item.showOnMobile }"
          >
            <router-link class="text-white font-semibold" :to="item.to">
              {{ item.label }}
            </router-link>
          </div>
        </div>
      </li>
      <li
        class="m-4 md:m-6 flex-grow-0 block md:block"
        v-if="userStore.isLoggedIn() && userStore.userInfo"
      >
        <n-popover trigger="hover">
          <template #trigger>
            <n-icon
              color="white"
              class="cursor-pointer text-[36px] md:text-[48px]"
            >
              <Battery
                v-if="roundedBalancePercentage === 0"
                class="text-red-500"
              />
              <Battery1 v-if="roundedBalancePercentage === 25" />
              <Battery2 v-if="roundedBalancePercentage === 50" />
              <Battery3 v-if="roundedBalancePercentage === 75" />
              <Battery4 v-if="roundedBalancePercentage === 100" />
            </n-icon>
          </template>
          <template #default>
            <div class="p-1 md:p-2 flex flex-col">
              <span>{{
                t('header.credits') + ': ' + userStore.team()?.credit_balance
              }}</span>
              <a class="font-semibold cursor-pointer" @click="buyCredits">
                {{ t('header.buyCredits') }}
              </a>
            </div>
          </template>
        </n-popover>
      </li>
      <li class="m-4 md:m-6 flex-grow-0 block" v-if="userStore.isLoggedIn()">
        <n-popover trigger="hover">
          <template #trigger>
            <n-icon
              color="white"
              class="cursor-pointer text-[36px] md:text-[48px]"
            >
              <UserAvatar />
            </n-icon>
          </template>
          <template #default>
            <div class="p-1 md:p-2">
              <a
                class="font-semibold cursor-pointer"
                @click="router.push('/dashboard/profile')"
              >
                {{ t('menu.my-profile') }}
              </a>
            </div>
            <div class="p-1 md:p-2">
              <a
                class="font-semibold cursor-pointer"
                @click="router.push('/dashboard/teams')"
              >
                {{ t('menu.my-teams') }}
              </a>
            </div>
            <template v-if="userInfo?.teams && userInfo.teams?.length > 1">
              <hr class="m-1 md:m-2" />
              <div class="p-1 md:p-2">{{ t('menu.switch-team') }}:</div>
              <div
                v-for="team in userInfo?.teams"
                v-bind:key="team.id"
                class="p-1 md:p-2"
              >
                <a
                  class="cursor-pointer"
                  :class="team.id === teamId ? 'font-extrabold' : ''"
                  @click="switchTeam(team)"
                >
                  {{ team.name }}
                </a>
              </div>
              <hr class="m-1 md:m-2" />
            </template>
            <div class="p-1 md:p-2">
              <a class="font-semibold cursor-pointer" @click="logout">
                {{ t('menu.logout') }}
              </a>
            </div>
          </template>
        </n-popover>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/UserStore'
import UserService from '@/services/UserService'
import { NIcon, NPopover, useDialog } from 'naive-ui'
import Battery from '@/assets/icons/Battery.svg'
import Battery1 from '@/assets/icons/Battery1.svg'
import Battery2 from '@/assets/icons/Battery2.svg'
import Battery3 from '@/assets/icons/Battery3.svg'
import Battery4 from '@/assets/icons/Battery4.svg'
import LogoDe from '@/assets/images/logo_de.svg'
import LogoEn from '@/assets/images/logo_en.svg'
import Menu from '@/assets/icons/Menu.svg'
import UserAvatar from '@/assets/icons/UserAvatar.svg'
import { useRouter } from 'vue-router'
import { computed, h, onMounted, onUnmounted } from 'vue'
import { emit as mitt } from '@/main'
import { storeToRefs } from 'pinia'
import type Team from '@/models/Team'

const dialog = useDialog()
const router = useRouter()
const userStore = useUserStore()
const { t } = useI18n()

const items = computed(() => [
  {
    label: t('menu.tools'),
    to: { name: 'home' },
    showOnMobile: false,
  },
  {
    label: t('menu.pricing'),
    to: { name: 'buy' },
    showOnMobile: false,
  },
  {
    label: t('menu.start'),
    to: { name: 'start' },
    showOnMobile: false,
  },
  {
    label: t('menu.login'),
    to: { name: 'login' },
    showOnMobile: true,
  },
])

const itemsLoggedIn = computed(() => [
  {
    label: t('menu.home'),
    to: { name: 'home' },
    showOnMobile: false,
  },
  {
    label: t('menu.buy'),
    to: { name: 'buy' },
    showOnMobile: false,
  },
])

const emit = defineEmits(['menu-click'])

onMounted(() => {
  mitt.on('unauthorized', unauthorized)
  mitt.on('insufficientCredits', insufficientCredits)
})

onUnmounted(() => {
  mitt.off('unauthorized', unauthorized)
  mitt.off('insufficientCredits', insufficientCredits)
})

const buyCredits = () => {
  router.push({ name: 'buy' })
}

const insufficientCredits = () => {
  dialog.error({
    title: t('common.insufficientCredits.title'),
    content: () => h('div', {}, t('common.insufficientCredits.message')),
    icon: () => h(NIcon, {}, [h(Battery)]),
    negativeText: t('common.cancel'),
    positiveText: t('common.insufficientCredits.buy'),
    onPositiveClick: () => {
      router.push({ name: 'buy' })
    },
  })
}

const { language, userInfo, teamId } = storeToRefs(userStore)

const logout = () => {
  UserService.logout()
  router.push({ name: 'home' })
}

const roundedBalancePercentage = computed((): number => {
  const team = userStore.team()
  if (!team || !team.last_credit_refill || !team.credit_balance) return 0
  if (team.credit_balance <= 0) return 0
  if (team.credit_balance > team.last_credit_refill) return 100
  const percentage = (team.credit_balance / team.last_credit_refill) * 100
  return Math.round(percentage / 25) * 25
})

const switchTeam = (team: Team) => {
  userStore.teamId = team.id
}

const unauthorized = () => {
  userStore.teamId = undefined
  userStore.token = undefined
  userStore.userInfo = undefined
  router.push({ name: 'login' })
}
</script>
